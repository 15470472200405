<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <div class="container request--book-container">
      <page-loading :show="loading" />
      <div class="left--col-container">
        <div class="request--book-content">
          <listing-info :listing="listing"></listing-info>
          <promo-section />
          <user-data-section ref="userDataSection" />
          <div class="request--book-agreement">
            <div class="left--part">
              <div class="title--text">
                {{ $t('booking.modal.customAgreement.header') }}
              </div>
              <div class="desc">
                {{ $t('booking.modal.customAgreement.message') }}
              </div>
              <div class="d-flex align-center mt-2" v-if="customAgreement && customAgreement.file">
                <div>
                  {{ customAgreement.file.name }}
                </div>
                <div class="btn btn-danger ml-3" @click="removeAgreement">
                  {{ $t('addListing.btn.delete') }}
                </div>
              </div>
              <vue-dropzone
                v-show="false"
                ref="agreementDropzone"
                id="dropzone"
                :options="dropzoneOptions"
                @vdropzone-sending="sending"
                @vdropzone-success="success"
              />
            </div>
            <div class="right--part">
              <button class="btn btn-primary" @click="uploadAgreement">
                {{ $t('booking.modal.customAgreement.uploadBtn') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="right--col-container">
        <action
          v-if="total_rent"
          :duration="lamaSewa"
          :startDate="moveDateName"
          :endDate="endDateName"
          :totalRentPrice="total_rent"
          :discountPrice="discount_price"
          :chosenPromo="chosenPromo"
          :promoValid="!promoWarning"
          :subTotal="harga_sewa"
          @submit="submit"
        />
      </div>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import ListingInfo from '@/components/utils/listing-info';
import Action from '@/components/request-to-book/action';
import { mapState } from 'vuex';
const PageLoading = () => import('@/components/content-loading/page-loading');
const PromoSection = () => import('@/components/request-to-book/promo-section');
const UserDataSection = () => import('@/components/request-to-book/user-data-section');
import vue2Dropzone from 'nuxt-dropzone';

export default {
  components: {
    DefaultLayout,
    ListingInfo,
    Action,
    PageLoading,
    vueDropzone: vue2Dropzone,
    PromoSection,
    UserDataSection,
  },
  data() {
    return {
      dropzoneOptions: {
        url: `${this.$store.state.global.apiUrl}/api/v2/file_content/upload_pdf`,
        maxFilesize: 10,
        acceptedFiles: '.pdf',
        paramName: 'file',
      },
    };
  },
  async fetch({ store, query, $date, route }) {
    store.commit('head/RESTORE_DEFAULT_VALUE', route);
    store.dispatch('v2/rentTransaction/initiate/restoreInitialState');
    // const date = $date.parse(query.date, 'MM/dd/yyyy');
    const date = $date.fromUnixTime(query.date);
    // const dateFormatted = $date.format(date, 'dd-MM-yyyy');
    store.commit('v2/rentTransaction/initiate/SET_START_TIME', query.time);
    store.commit('v2/rentTransaction/initiate/SET_START_DATE', date);
    store.commit('v2/rentTransaction/initiate/SET_DURATION', query.duration);
    store.commit('v2/rentTransaction/initiate/SET_RENT_PERIOD', query.rpid);
    store.commit('v2/rentTransaction/initiate/SET_LISTING_UUID', query.lid);
    store.dispatch('global/fetch');
  },
  computed: {
    ...mapState({
      rentPeriodId: (state) => state.v2.rentTransaction.initiate.rentPeriod,
      startTime: (state) => state.v2.rentTransaction.initiate.startTime,
      startDate: (state) => state.v2.rentTransaction.initiate.startDate,
      duration: (state) => state.v2.rentTransaction.initiate.duration,
      listing: (state) => state.v2.rentTransaction.initiate.listing,
      listingUuid: (state) => state.v2.rentTransaction.initiate.listingUuid,
      chosenPromo: (state) => state.v2.rentTransaction.initiate.promo.chosenPromo,
      promoWarning: (state) => state.v2.rentTransaction.initiate.promo.warningMessage,
      lamaSewa: (state) => state.v2.rentTransaction.initiate.pricing.lamaSewa,
      total_rent: (state) => state.v2.rentTransaction.initiate.pricing.totalRent,
      harga_sewa: (state) => state.v2.rentTransaction.initiate.pricing.hargaSewa,
      endDateName: (state) => state.v2.rentTransaction.initiate.pricing.endDateName,
      moveDateName: (state) => state.v2.rentTransaction.initiate.pricing.moveDateName,
      discount_price: (state) => state.v2.rentTransaction.initiate.pricing.discountPrice,
      currentPhone: (state) => (state.global.user ? state.global.user.phone : null),
    }),
    breadcrumbs() {
      if (!this.listing) return [];
      const typeString =
        this.listing.type === 'R' ? this.$t('general.rent') : this.$t('general.sell');
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: `${typeString} ${this.listing.property_type_name}`,
          to: `/sewa/search?property_type_id=${this.listing.property_type_id}`,
        },
        {
          text: this.listing.listing_title,
          to: this.listing.web_url,
        },
        {
          text: this.$t('general.bookSpace'),
          to: this.$router.currentRoute.path,
        },
      ];
    },
    loading: {
      get() {
        return this.$store.state.v2.rentTransaction.initiate.loading;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/initiate/SET_LOADING', val);
      },
    },
    customAgreement: {
      get() {
        return this.$store.state.v2.rentTransaction.initiate.customAgreement;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/initiate/SET_CUSTOM_AGREEMENT', val);
      },
    },
    localPhone: {
      get() {
        return this.$store.state.v2.rentTransaction.initiate.localPhone;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/initiate/SET_LOCAL_PHONE', val);
      },
    },
  },
  methods: {
    timeLabel(listingOperational) {
      return listingOperational + '.00';
    },
    uploadAgreement() {
      this.$refs.agreementDropzone.dropzone.hiddenFileInput.click();
    },
    removeAgreement() {
      this.customAgreement = {};
    },
    // eslint-disable-next-line no-unused-vars
    sending(file, xhr, formData) {
      xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.global.token}`);
    },
    success(file, response) {
      console.log('SUCCESS UPLOAD AGREEMENT!', file);
      if (response.type === 'success') {
        this.customAgreement = {
          file: file,
          uuid: response.data.uuid,
        };
      }
    },
    async getTotalRent() {
      let moveTime = null;
      if (this.rentPeriodId == 5) {
        moveTime = this.timeLabel(this.startTime);
      }
      let payload = {
        periode_id: this.rentPeriodId,
        move_date: this.$date.format(this.startDate, 'yyyy-MM-dd'),
        move_time: moveTime,
        duration: this.duration,
        uuid: this.listingUuid,
      };
      if (!this.promoWarning) {
        payload.promo_uuid = this.chosenPromo?.uuid;
      }
      await this.$store.dispatch('v2/rentTransaction/initiate/pricing/getTotalRent', payload);
    },
    validate() {
      return this.$refs.userDataSection.$validate();
    },
    async submit() {
      try {
        const isValid = await this.validate();
        if (isValid) {
          this.$store.commit('v2/rentTransaction/initiate/SET_PHONE', this.localPhone);
          const result = await this.checkUnpaidBooking();
          const allowBook = result.is_allowed;
          const warningBook = result.warning_message;
          console.log(allowBook);
          if (allowBook === true) {
            const response = await this.$store.dispatch(
              'v2/rentTransaction/initiate/submit',
              this.listing.uuid,
            );
            if (response) {
              this.$router.push({
                path: '/mybooking/detail',
                query: {
                  type: 'RENT_TRANSACTION',
                  uuid: response,
                },
              });
              this.customAgreement = {};
            }
          } else {
            this.$swal(this.$t('booking.swal.paymentExist.title'), warningBook, 'error');
            this.$swal({
              title: this.$i18n.t('booking.swal.paymentExist.title'),
              text: warningBook,
              type: 'error',
              confirmButtonText: this.$t('booking.swal.paymentExist.checkBtn'),
            }).then(async (result) => {
              if (result.value) {
                this.$router.push('/dashboard/mybooking');
              }
            });
          }
        }
        // eslint-disable-next-line no-empty
      } catch (_) {}
    },
    async checkUnpaidBooking() {
      try {
        this.loading = true;
        console.log('qweqeee', this.$route.query);
        let checkBooking = await this.$store.dispatch(
          'v2/rentTransaction/initiate/isBookingAllowed',
          this.listing.uuid,
        );
        return checkBooking;
      } catch (e) {
        console.log('ERRORl', e);
        return false;
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    chosenPromo() {
      this.getTotalRent();
    },
  },
  async mounted() {
    const resultCheck = await this.$store.dispatch(
      'v2/rentTransaction/initiate/checkRequestBooking',
    );
    console.log('resykt cecj', resultCheck);
    if (!resultCheck.valid) {
      await this.$swal(
        this.$t('errors.bookingRequest.initiateTitle'),
        resultCheck.errorMessage,
        'error',
      );
      this.$router.back();
    } else {
      await this.$store.dispatch('v2/rentTransaction/initiate/getInitData');
      if (this.currentPhone) this.localPhone = this.currentPhone;
      this.getTotalRent();
    }
  },
};
</script>
