<template>
  <section class="request--book-action">
    <div class="content--row">
      <div>{{ $t('general.startFrom') }}</div>
      <div>{{ startDate }}</div>
    </div>
    <div class="content--row">
      <div>{{ $t('general.until') }}</div>
      <div>{{ endDate }}</div>
    </div>
    <div class="content--row">
      <div>{{ $t('general.rentDuration') }}</div>
      <div>{{ duration }}</div>
    </div>
    <div class="content--row">
      <div>{{ $t('general.subPrice') }}</div>
      <div>{{ $n(subTotal, 'currency', 'id-ID') }}</div>
    </div>
    <div class="content--row" v-if="discountPrice && chosenPromo && promoValid">
      <div>Promo {{ chosenPromo.promo_code }}</div>
      <div class="discount">- {{ $n(discountPrice, 'currency', 'id-ID') }}</div>
    </div>
    <div class="total--row">
      <div>{{ $t('general.totalPrice') }}</div>
      <div>{{ $n(totalRentPrice, 'currency', 'id-ID') }}</div>
    </div>
    <button :disabled="!promoValid" class="btn btn-primary" @click="$emit('submit')">
      {{ $t('general.bookSpace') }}
    </button>
  </section>
</template>

<script>
export default {
  name: 'request-book-action',
  props: [
    'duration',
    'startDate',
    'endDate',
    'totalRentPrice',
    'discountPrice',
    'chosenPromo',
    'subTotal',
    'promoValid',
  ],
  methods: {},
};
</script>
